import { withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import MensagemAtendimento from "../../../../components/MensagemAtendimento";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import PreviewDocumento from "../../Prontuario/PreviewDocumento";
import styles from "./ProntuarioStyle";
import { MAX_IMAGE_MESSAGE_INTERVAL_TO_GROUP_IN_MIN, entradaTipos, mensagensColor } from "./Constants";
import CaixaEntradaProntuario from "../../Prontuario/components/CaixaEntradaProntuario";
import { observer } from "mobx-react-lite";
import { findByIdEntradaProntuarioProcedimentoRealizado } from "../../../../services/ProcedimentosRealizadosService";
import { findAnamneseByIdEntradaProntuario } from "../../../../services/AnamneseService";
import { findByIdEntradaProntuarioAfericaoVital } from "../../../../services/AfericaoVitalService";
import { findGuiaInternacaoByIdEntradaProntuario } from '../../../../services/GuiaInternacaoService';

import { UNIDADE_LOGADA_KEY } from "../../../../services/storage";
import { groupEntradaTypeImage } from "./utils/utils";
import ModalCompatilhar from "../../Prontuario/components/ModalCompatilhar";

const profileDefault = require("../../../../assets/img/svg/img-profile-default.svg");

const Prontuario = observer(
  ({
    classes,
    prontuarioStore,
    googleAnalyticsStore,
    SADTStore,
    handleClickSADT,
    loadMoreMensagens,
    atualizaState,
    guiaInternacaoStore,
    utilizaPlugzapi
  }) => {
    const {
      sujeitoAtencaoSelected,
      loadingAllEntradas,
      loadingMoreMensagens,
      listEntradas,
      hasMoreMensagens,
      previewDocumento,
    } = prontuarioStore;

    const scrollMessagesRef = useRef(null);
    
    const [openModalConfirmacao,setOpenModalConfirmacao]=useState(false)
    const [idEntradaProntuario,setIdEntradaProntuario]=useState(null)

    const isPrever =
      JSON.parse(localStorage.getItem("_immortal|" + UNIDADE_LOGADA_KEY))?.rede
        ?.whitelabel?.nome === "ANGEPLUS";

        useEffect(() => {
          if (scrollMessagesRef.current && prontuarioStore.messagesScrollToBottom) {
            messagesScrollToBottom();
          }
        }, [listEntradas]);
        


    const messagesScrollToBottom = () => {
      if (scrollMessagesRef && scrollMessagesRef.current) {
        scrollMessagesRef.current.scrollTop =
          scrollMessagesRef.current.scrollHeight;
        prontuarioStore.messagesScrollToBottom = false;
      }
    };

    const handleOpenModalImage = (entrada) => {
      prontuarioStore.openModalImage(entrada);
    };

    const handleClickMessage = async (entrada) => {
      switch (entrada.tipo) {
        case "RECEITA":
          prontuarioStore.receituario = {
            open: true,
            entrada,
          };
          return;

        case "DOCUMENTO":
          entrada.tipo = "LOADING_DOCUMENTO";
          await prontuarioStore.openDocumentoByIdEntradaProntuario(entrada.id);
          entrada.tipo = "DOCUMENTO";
          return;
        case "GUIA_SERVICO":
          SADTStore.entradaProntuario = entrada;
          handleClickSADT();
          return;

        case "PROCEDIMENTO_REALIZADO":
          findByIdEntradaProntuarioProcedimentoRealizado(entrada.id).then(
            (response) => {
              const content =
                response.data.data
                  .findByIdEntradaProntuarioProcedimentoRealizado;

              atualizaState({
                procedimentoRealizadoId: content.id || "",
                openProcedimentoRealizadoModal: true,
              });
            }
          );
          return;

        case "ANAMNESE":
          findAnamneseByIdEntradaProntuario(entrada.id).then((response) => {
            atualizaState({
              anamneseSelecionado: response,
              showModalAnamnese: true,
            });
          });
          return;

        case "AVALIACAO_ANTROPOMETRICA":
          findByIdEntradaProntuarioAfericaoVital(entrada.id).then(
            (response) => {
              atualizaState({
                triagemSelecionado: response,
                openTriagem: true,
              });
            }
          );
          return;
        
        case "GUIA_INTERNACAO":
            try {
              const idGuiaInternacao = await findGuiaInternacaoByIdEntradaProntuario(entrada.id);
              if (idGuiaInternacao) {
                guiaInternacaoStore.guiaInternacaoSelectedId = idGuiaInternacao;
                guiaInternacaoStore.openGuiaInternacaoModal = true;
              }
            } catch(error) {
              console.error(error);
            }
            break;

        case "IMAGEM": {
          break; 
        }

        case "ARQUIVO":{
          break;
        }

        default:
          findByIdEntradaProntuarioAfericaoVital(entrada.id).then(
            (response) => {
              atualizaState({
                triagemSelecionado: response,
                openTriagem: true,
              });
            }
          );
          return;
      }
    };

    const eventoGA = (categoria, acao, rotulo) => {
      ga(
        googleAnalyticsStore.profissionalSaude + ".send",
        "event",
        categoria,
        acao,
        rotulo
      );
    };

    const handleExcluirArquivo = (arquivo) => {
      const posicao = parseInt(arquivo.id);
      const files = prontuarioStore.previewDocumento.files;

      const listaArquivos = [
        ...files.slice(0, posicao),
        ...files.slice(posicao + 1, files.length),
      ];

      prontuarioStore.previewDocumento = {
        ...prontuarioStore.previewDocumento,
        files: listaArquivos,
      };
    };

    const handleClosePreviewDocumento = () => {
      prontuarioStore.closePreviewDocumento();
    };

    const handleChangeDescricaoPreviewDocumento = (text) => {
      prontuarioStore.previewDocumento.descricao = text;
    };

    const handleSendDocumento = async () => {
      try {
        await prontuarioStore.createEntrada();
      } catch (error) {
        console.error(error);
      }
    };

    const handleDuplicarDocumento = () => {
      atualizaState({
        openPreencherDocumento: true,
        isDuplicar: true,
      });
      prontuarioStore.previewDocumento = {
        ...prontuarioStore.previewDocumento,
        open: false,
      };
    };

    const entradaTipoRight = (entrada) => {
      return entradaTipos.includes(entrada);
    };

    if (!sujeitoAtencaoSelected && !loadingAllEntradas) {
      return (
        <div className={classes.contentNotFoundContainer}>
          <h3 className={classes.notFoundTitle}>
            Nenhum agendamento selecionado
          </h3>
        </div>
      );
    }
 
    const handleCloseModalConfirmacao = () => {
      setOpenModalConfirmacao(false)

    }

    const handleOpenModalCompartilhar = (e) => {
      setIdEntradaProntuario(e.id)
      setOpenModalConfirmacao(true)

    }

    const handleClickCompartilharWhatsApp = async () => {
      const { agendamentoSelected } = prontuarioStore;
      const telefone = `${agendamentoSelected.telefoneDDI + agendamentoSelected.telefone}`

      try {
        await prontuarioStore.enviarItemProntuarioPaciente({ telefone, entradaProntuarioId: idEntradaProntuario })
      } catch (error) {
        console.error(error)
      }finally{
        setOpenModalConfirmacao(false)
      }
    }


    const listEntradasWithGroupedImages = groupEntradaTypeImage(listEntradas.reverse(), MAX_IMAGE_MESSAGE_INTERVAL_TO_GROUP_IN_MIN).reverse();

    return (
      <>
        <div className={classes.contentGridContainer}>
          <div className={classes.contentMensagens}>
            <Scroll
              rootRef={scrollMessagesRef}
              initialLoad={false}
              isReverse
              hasMore={hasMoreMensagens}
              loadMore={loadMoreMensagens}
              pageStart={0}
              isLoading={loadingMoreMensagens}
              className={classes.scroll}
            >
              {listEntradasWithGroupedImages &&
                listEntradasWithGroupedImages.map((entrada, index) => (
                  <div
                    key={`ativo-${entrada.ativo}-${index}`}
                    className={classes.messagesContainer}
                  >
                    {entrada.showDate && (
                      <div className={classes.messagesDateContainer}>
                        <span className={classes.messagesDate}>
                          {entrada.date}
                        </span>
                      </div>
                    )}
                    <MensagemAtendimento
                      hora={entrada.hour}
                      text={entrada.texto}
                      color={mensagensColor[entrada.tipo]}
                      isRight={entradaTipoRight(entrada.tipo)}
                      image={entrada.urlFotoPerfil || profileDefault}
                      tipo={entrada.tipo}
                      urlDownload={entrada.urlDownload}
                      urlMiniatura={entrada.urlMiniatura}
                      urls={entrada?.urls}
                      entrada={entrada}
                      onClickImage={
                        entrada.tipo === "IMAGEM" ? handleOpenModalImage : null
                      }
                      isPrever={isPrever}
                      onClickMessage={
                        entradaTipoRight
                          ? () => handleClickMessage(entrada)
                          : null
                      }
                      utilizaPlugzapi={utilizaPlugzapi}
                      handleOpenModalCompartilhar={handleOpenModalCompartilhar}
                    />
                  </div>
                ))}
            </Scroll>
          </div>
          <CaixaEntradaProntuario
            eventoGA={eventoGA}
            scrollMessagesRef={scrollMessagesRef}
            messagesScrollToBottom={messagesScrollToBottom}
          /> 
          <ModalCompatilhar open={openModalConfirmacao} 
				  onClose = {handleCloseModalConfirmacao}
				  onClick={handleClickCompartilharWhatsApp}
        />
          {previewDocumento.open && (
            <PreviewDocumento
              excluirArquivo={handleExcluirArquivo}
              preview={previewDocumento}
              onClose={handleClosePreviewDocumento}
              duplicarDocumento={handleDuplicarDocumento}
              onChangeDescricao={handleChangeDescricaoPreviewDocumento}
              onSend={handleSendDocumento}
              screen="Atendimento"
            />
          )}
        </div>
      </>
    );
  }
);

const ProntuarioWithStyle = withStyles(styles)(Prontuario);
const stores = [
  "prontuarioStore",
  "atendimentoStore",
  "sujeitoAtencaoStore",
  "googleAnalyticsStore",
  "SADTStore",
  "receituarioStore",
  "usuarioStore",
  "termoUsoStore",
  "guiaInternacaoStore",
  "unidadeStore",
];
export default inject(...stores)(ProntuarioWithStyle);

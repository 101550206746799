import React, { useEffect, useState } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {
  Container,
  ContentButtons,
  DialogStyled,
  Header,
  Title,
} from "./styles";
import {
  componentTab,
  getErrorMessage,
  internacaoDefault,
  optionsTab,
  responsavelDefault,
  tabs,
  tiposEncaminhamentos,
  tiposLeitos,
} from "./constants";
import { Button } from "../../../components/ui/Buttons";
import CloseIcon from "../../../components/Icon/Close";
import SaveIcon from "../../../components/Icon/Save";
import {
  findByIdSujeitoAtencao,
  saveInternacao,
} from "../../../services/InternacaoService";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import { getProfissionalSaudeLogado } from "../../../services/UsuarioService";
import FinanceiroMenuIcon from "../../../components/Icon/FinanceiroMenu";
import moment from "moment";
import { linkGuiaFaturamento } from "../../../services/FinanceiroService";

const InternacaoModal = observer(
  ({
    openModal,
    handleClose,
    atendimentoStore,
    agendamentoId,
    dadosInternacao,
  }) => {
    const { objView } = atendimentoStore;
    const { sujeitoAtencao } = objView;
    const { convenio, profissionalSaude, solicitante, procedimentos } =
      objView || {};

    const [tabSelected, setTabSelected] = useState(tabs.DADOS_CADASTRAIS);
    const [internacao, setInternacao] = useState(
      dadosInternacao || internacaoDefault
    );
    const [loadingSave, setLoadingSave] = useState(false);

    const { leitosOcupados, dadosComplementares } = internacao || {};

    useEffect(() => {
      internacao?.id ? adaptaDadosInternacao() : getDadosInternacao();
    }, []);

    const adaptaDadosInternacao = () => {
      const dadosLeitosOcupados = leitosOcupados.map((leito) => {
        const { tipoLeito } = leito;

        return {
          ...leito,
          tipoLeito: tiposLeitos.find((item) => item.value === tipoLeito),
        };
      });

      const dadosEncaminhamento =
        tiposEncaminhamentos.length > 0 &&
        tiposEncaminhamentos.find(
          (item) => item.value === dadosComplementares.encaminhamento
        );

      const cids = internacao.cids.length > 0 && internacao.cids.map((cid) => {
        const { id, sequencia, cid10Subcategoria } = cid;
        
        return {
          id,
          sequencia,
          cid: cid10Subcategoria,
          descricao: cid10Subcategoria?.descricao,
          diagnostico: cid10Subcategoria?.descricaoAbreviada,
        };
      });

      const dadosInternacao = {
        ...internacao,
        leitosOcupados: dadosLeitosOcupados,
        dadosComplementares: {
          ...dadosComplementares,
          ...(dadosEncaminhamento && { encaminhamento: dadosEncaminhamento }),
        },
        cids,
      };

      setInternacao((prevState) => ({
        ...prevState,
        ...dadosInternacao,
      }));
    };

    const getDadosInternacao = async () => {
      const response = await findByIdSujeitoAtencao(sujeitoAtencao?.id);
      const profissionalAtendente = await getProfissionalSaudeLogado();

      const {
        endereco,
        contato,
        nomeResponsavel,
        documentoResponsavel,
      } = response;

      const procedimentosSolicitados = procedimentos.map((item, index) => {
        const convenioProcedimento = item?.procedimento?.convenioProcedimentos?.find(
          (item) => item.convenio.id === convenio.id
        );

        return {
          ...item,
          sequencia: index + 1,
          valor: convenioProcedimento?.valor || 0,
          profissionalSaude,
        };
      });

      setInternacao((prevState) => ({
        sujeitoAtencao: response,
        acompanhante: {
          nome: nomeResponsavel,
          endereco,
          contato,
          documento: documentoResponsavel,
          rg: "",
          vinculo: "",
        },
        responsavel: responsavelDefault,
        dadosComplementares: {
          ...prevState.dadosComplementares,
          convenio: convenio,
          profissionalResponsavel: profissionalSaude,
          profissionalSolicitante: solicitante,
          profissionalAtendente,
        },
        leitosOcupados: [],
        procedimentosSolicitados,
        procedimentosExecutados: [],
        cids: [],
      }));
    };

    const changeTabSelected = (tabSelected) => {
      setTabSelected(tabSelected);
    };

    const getVariables = () => {
      const { dadosComplementares, leitosOcupados, cids, procedimentosExecutados, procedimentosSolicitados } = internacao || {};

      const dadosComplementaresAdapted = {
        ...internacao.dadosComplementares,
        encaminhamento: dadosComplementares?.encaminhamento?.value || null,
        convenio: dadosComplementares?.convenio?.id
          ? { id: dadosComplementares.convenio.id }
          : null,
        profissionalAtendente: dadosComplementares?.profissionalAtendente?.id
          ? { id: dadosComplementares?.profissionalAtendente?.id }
          : null,
        dataGuia: dadosComplementares?.dataGuia
          ? moment(dadosComplementares.dataGuia).format("YYYY-MM-DD")
          : null,
        numeroGuia: dadosComplementares?.numeroGuia || null,
      };

      const leitosOcupadosAdapted =
        leitosOcupados.length > 0
          ? leitosOcupados.map((item) => ({
            ...item,
            tipoLeito: item?.tipoLeito?.value || null,
            dataInicioOcupacao: item?.dataInicioOcupacao
              ? moment(item.dataInicioOcupacao).format("YYYY-MM-DDTHH:mm:ss")
              : null,
            dataFimOcupacao: item?.dataFimOcupacao
              ? moment(item.dataFimOcupacao).format("YYYY-MM-DDTHH:mm:ss")
              : null,
          }))
          : [];

      const cidsAdapted =
        cids.length > 0
          ? cids.map((item) => ({
              ...(item.id && { id: item.id }),
              cid10Subcategoria: {id: item.cid.id},
              sequencia: item.sequencia,
            }))
          : [];

      const procedimentosSolicitadosAdapted = procedimentosSolicitados.length > 0 ?
        procedimentosSolicitados.map((item) => {
          delete item.nomeProcedimento;
          !internacao.id && delete item.id

          return {
            id: item.id,
            procedimento: { id: item.procedimento.id },
            sequencia: item.sequencia,
            valor: item.valor,
            profissionalSaude: { id: item.profissionalSaude.id },
            codigoAMB: item.codigoAMB,
          }
        }
      ) : [];

      const procedimentosExecutadosAdapted = procedimentosExecutados.length > 0 ?
        procedimentosExecutados.map((item) => {
          delete item.nomeProcedimento;

          return {
            ...item,
            ...(item.quantidade && {quantidade: parseInt(item.quantidade)}),
            dataRealizacao: moment(item.dataRealizacao).format("YYYY-MM-DDTHH:mm:ss"),
          }
        }
      ) : [];

      delete internacao.sujeitoAtencao.idade;

      return {
        ...internacao,
        agendamento: { id: agendamentoId },
        sujeitoAtencao: { id: sujeitoAtencao.id },
        dadosComplementares: dadosComplementaresAdapted,
        leitosOcupados: leitosOcupadosAdapted,
        procedimentosExecutados: procedimentosExecutadosAdapted,
        procedimentosSolicitados: procedimentosSolicitadosAdapted,
        cids: cidsAdapted,
      };
    };

    const handleSave = async () => {
      try {
        const {
          acompanhante,
          sujeitoAtencao,
          responsavel,
          procedimentosSolicitados,
          leitosOcupados,
        } = internacao;

        const { messageError, error } = getErrorMessage({
          sujeitoAtencao,
          acompanhante,
          responsavel,
          procedimentosSolicitados,
          leitosOcupados,
        });

        if (error) {
          atendimentoStore.showAlertMessage({
            isOpen: true,
            message: messageError,
            variant: "error",
          });

          return;
        }

        setLoadingSave(true);

        const sujeitoAtencaoInternacao = getVariables();

        await saveInternacao({ sujeitoAtencaoInternacao });

        handleClose();
        const notification = {
          isOpen: true,
          message: "Internação salva com sucesso!",
          variant: "success",
        };
        atendimentoStore.showAlertMessage(notification);
      } catch (error) {
        const notification = {
          isOpen: true,
          message: "Erro ao salvar a internação.",
          variant: "error",
        };
        atendimentoStore.showAlertMessage(notification);
      } finally {
        setLoadingSave(false);
      }
    };

    const handleFaturamento = async () => {
      try {
        const urlAzulControle = await linkGuiaFaturamento({ internacaoId: internacao.id });
        window.open(urlAzulControle);
      } catch (error) {
        atendimentoStore.showAlertMessage({
          isOpen: true,
          message: "Erro ao acessar o financeiro",
          variant: "error",
        });
      }
    };

    const handleField = (field, value) => {
      setInternacao((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    };

    const renderTab = () => {
      const Component = componentTab[tabSelected];
      return <Component internacao={internacao} handleField={handleField} />;
    };

    return (
      <>
        <PageTitle title="Agenda - Agendamento Internação" />
        <DialogStyled
          maxWidth="md"
          fullWidth
          open={openModal}
          onClose={handleClose}
        >
          <Container>
            <Header>
              <Title>Internação</Title>
              <ContentButtons>
                <Button
                  shape="circle"
                  bgColor={"#00B0AE"}
                  onClick={handleSave}
                  disabled={loadingSave}
                >
                  <SaveIcon />
                </Button>
                <Button
                  shape="circle"
                  bgColor={"#00B0AE"}
                  onClick={handleFaturamento}
                  disabled={!internacao?.id || loadingSave}
                >
                  <FinanceiroMenuIcon />
                </Button>
                <Button
                  shape="circle"
                  bgColor={"#FB7676"}
                  onClick={handleClose}
                  disabled={loadingSave}
                >
                  <CloseIcon />
                </Button>
              </ContentButtons>
            </Header>
            <MultiToggleButtons
              options={optionsTab}
              tabSelected={tabSelected}
              changeTabSelected={changeTabSelected}
            />
            {renderTab()}
          </Container>
        </DialogStyled>
      </>
    );
  }
);

export default inject("atendimentoStore")(InternacaoModal);

import React from "react";
import { inject } from "mobx-react";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import {
	Fab,
	CircularProgress,
	Grid,
	DialogTitle,
	DialogContent,
} from "@material-ui/core";
import Dialog from "../../../components/Dialog/Dialog";
import {
	Print as PrintIcon,
	FileCopy as DuplicateIcon,
	Save as SaveIcon,
	Close as CloseIcon,
	Description as DescriptionIcon,
	Star as StarIcon,
	GetApp as DownloadIcon,
} from "@material-ui/icons";
import RecipeType from "./RecipeType";
import Patient from "./Patient";
import RecipeList from "./RecipeList";
import string from "../../../utils/string";
import printPage from "../../../utils/printPage";
import base64ToBlob from "../../../utils/base64ToBlob";
import DrugForm from "./DrugForm";
import OftalmoForm from "./OftalmoForm";
import TokenDialog from '../../../components/Dialog/TokenDialog';
import InputTokenDialog from '../../../components/Dialog/InputTokenDialog';
import localStorageService, {
	ACCESS_TOKEN_KEY,
	UNIDADE_LOGADA_KEY,
} from "../../../services/storage";
import { buildUrlDownload } from "../../../config/config";
import { TypeModalEnumReceituario } from "../../../stores/Receituario.store";
import { Radio, RadioGroup } from '../../../components/RadioButton/RadioButtonModal';
import Lista from "./Lista";
import Notification from "../../../components/Notification";
import { findAllEstadoList } from "../../../services/receituarioService";
import { consultaDeCep, findByIdSujeitoAtencaoWithEstadoList, getSujeitoAtencaoReceitaControlada, updateEnderecoSujeitoAtencao } from "../../../services/SujeitoAtencaoService";
import getTipoImpressao from "../../../utils/getTipoImpressao";
import InformacoesImportantesModal from "../InformacoesImportantes/InformacoesImportantesModal";
import { checkUserRole } from "../../../utils/checkUserRole";
import PageTitle from "../../../components/PageTitle/PageTitle";

import { styles } from "./styles";
import { findEstadoById } from "../../../services/EnderecoService";
import HorizontalLineIcon from "../../../components/Icon/HorizontalLineIcon";
import CompartilharIcon from "../../../components/Icon/CompartilharIcon";
import ModalCompatilhar from "../Prontuario/components/ModalCompatilhar";

const defaultState = {
	typeCurrentRecipe: "SIMPLES",
	idReceita: null,
	doctor: {},
	patient: {},
	unidade: {},
	accessToken: null,
	useTypeSelected: null,
	recipes: [],
	receitaOftalmologica: {},
	isEditingRecipe: false,
	newRecipes: [
		{
			drug: "",
			pharmaceuticalForm: "",
			amount: "",
			ascription: "",
		},
	],
	date: moment().format("DD/MM/YYYY"),
	states: [],
	cities: [],
	isFetchingCities: false,
	lastIdRecipe: 1,
	drugs: [],
	loadingModal: true,
	creatingReceita: false,
	receitaCreated: false,
	printing: false,
	isDuplicate: false,
	printData: '',
	readOnly: false,
	recipe: {
		drug: "",
		amount: "",
		ascription: "",
		routeAdm: "",
	},
	isSigned: false,
	openSignQuestionDialog: false,
	openTokenDialog: false,
	tokenDialogActionClicked: false,
	signToken: "",
	tokenError: false,
	nomeModelo: "",
	statusNovoModelo: true,
	sugestoesLoading: false,
	modelosLoadingModal: false,
	notification: {
		isOpen: false,
		message: ''
	},
	openModalModelo: false,
	idDrugSelecionado: "",
	showDadosImportantesModal: false,
	utilizaPlugzapi:false,
	openModalConfirmacao:false,
	isLoading:false,
	hideButton:false,
	compartilhar:false,
	idEntradaProntuario:null,
};

export const tipoListagem = [
	{ name: 'Sugestões', icon: StarIcon, value: 'sugestoes', selected: true },
	{ name: 'Modelos', icon: DescriptionIcon, value: 'modelos', selected: false }
];

export const statusNovoModelo = [
	{ name: 'Ativo', label: 'Ativo', value: true },
	{ name: 'Inativo', label: 'Inativo', value: false },
];
@inject(
	"prontuarioStore",
	"configuracaoImpressaoStore",
	"profissionalSaudeStore",
	"receituarioStore",
	"atendimentoStore",
	"unidadeStore",
)
class Receituario extends React.PureComponent {

	constructor(props) {
		super(props);
		this.refEditorObservacao = React.createRef();
	}

	state = {
		...defaultState,
		entrada: {},
		onOkPrintSigned: false,
		onCancelPrintSigned: false
	};

	async componentDidMount() {
		const {
			entrada,
			prontuarioStore,
			profissionalSaudeStore,
			receituarioStore,
		} = this.props;

		const { sujeitoAtencaoSelected } = prontuarioStore;
		const roleEntradaProntuarioRead = checkUserRole('ROLE_ENTRADA_PRONTUARIO_READ');
		const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
		receituarioStore.accessToken = accessToken;
		receituarioStore.inputAdscricao = "";
		receituarioStore.inputRemedio = "";

		this.setState({
			showDadosImportantesModal: roleEntradaProntuarioRead && sujeitoAtencaoSelected?.informacoesImportantes?.length > 0,
			readOnly: entrada?.id,
		});

		await profissionalSaudeStore.carregaProfissionalSaude();
		receituarioStore.possuiToken = await receituarioStore.possuiTokenAssinaturaValido();

		await this.loadReceitaData();
		await this.loadMedicamentoQuantidadeUso();
		await prontuarioStore.checkUseDigitalSignature();
		receituarioStore.sugestoesPagAtual = 0;
		receituarioStore.sugestoes = [];
	}


	handleMinimize = () => {
		this.props.handleMinimize();
	}

	handleCloseModal = () => {
		this.props.handleClose();
		this.setState({hideButton:false})
	}

	loadReceitaData = async () => {
		const { receituarioStore, prontuarioStore, entrada } = this.props;
		const { drugTemp, amountTemp, ascriptionTemp, recipesTemp, usoTemp } = receituarioStore;

		if (!entrada?.id) {
			this.setState({ loadingModal: false });
			this.getSujeitoAtencaoReceitaControlada();
			if (drugTemp || amountTemp || ascriptionTemp || recipesTemp || usoTemp) {
				this.setState({ recipes: recipesTemp });
				this.setState({
					recipe: {
						drug: drugTemp,
						amount: amountTemp,
						ascription: ascriptionTemp,
						useType: usoTemp
					}
				});
			}
			try {
				const response = await findAllEstadoList();
				if (response.data.errors) {
					throw response.data.errors[0];
				}
				this.setState({ states: response.data.data.findAllEstadoList });
			} catch (error) {
				console.error(error);
			}
		} else {
			try {
				const content = await prontuarioStore.findReceitaByIdEntradaProntuario(
					entrada.id
				);

				this.setState({ entrada: content });
				this.loadReceita(content);
			} catch (error) {
				console.error(error);
			}
		}
	}

	loadMedicamentoQuantidadeUso = async () => {
		const { receituarioStore } = this.props;

		this.setState({ sugestoesLoading: true });
		await receituarioStore.findAllMedicamentoQuantidadeUso({
			pageableDTO: {
				pageSize: 10,
				pageNumber: receituarioStore.sugestoesPagAtual,
				sortDir: "DESC",
				sortField: "quantidadeUso"
			}
		})
		this.setState({ sugestoesLoading: false });
	}

	getSujeitoAtencaoReceitaControlada = async () => {
		const paciente = await getSujeitoAtencaoReceitaControlada(this.props.sujeitoAtencaoId);
		if (paciente) {
			const { id, nome, endereco, fotoPerfil } = paciente
			const dadosPacienteAdaptado = {
				id: id,
				name: nome,
				picture: fotoPerfil,
				zipCode: endereco?.cep,
				street: endereco?.nomeLogradouro,
				number: endereco?.numero,
				neighborhood: endereco?.bairro,
				state: {
					label: endereco?.estado?.uf,
					value: endereco?.estado?.id
				},
				city: {
					label: endereco?.municipio?.nome,
					value: endereco?.municipio?.id
				}
			}
			this.setState({ patient: dadosPacienteAdaptado });
		}
	}

	loadReceita = (content) => {
		const { endereco, id, nome } = content.sujeitoAtencao;

		let state = null;

		if (endereco?.estado?.id) {
			state = {
				value: endereco?.estado?.id,
				label: endereco?.estado?.uf,
			};
		}

		let city = null;

		if (endereco?.municipio?.id) {
			city = {
				value: endereco?.municipio?.id,
				label: endereco?.municipio?.nome,
			};
		}

		const { receitaItens, dataHoraLancamento, id: contentId, unidade, oftalmologica, tipo } = content;

		const getRecipeData = (item) => ({
			id: item.id,
			drug: {
				label: item.remedio,
				subtitulo: item.formaFarmaceutica,
				principioAtivo: item.principioAtivo,
			},
			amount: item.quantidade,
			ascription: {
				label: item.adscricao,
			},
			useType: item.receitaItemTipoUso,
			routeAdm: item.viaAdministracao
		});

		const recipes = receitaItens
			.map(getRecipeData);

		this.setState({
			...defaultState,
			idReceita: contentId,
			date: moment(dataHoraLancamento).format("DD/MM/YYYY"),
			loadingModal: false,
			receitaCreated: true,
			readOnly: true,
			unidade: unidade,
			typeCurrentRecipe: tipo,
			receitaOftalmologica: oftalmologica,
			doctor: this.props.profissionalSaude,
			patient: {
				id: id,
				name: nome,
				street: endereco?.nomeLogradouro || "",
				number: endereco?.numero || "",
				zipCode: endereco?.cep || "",
				neighborhood: endereco?.bairro || "",
				city,
				state,
				isEditing: false,
				isHidden: false,
			},
			recipes,
		});
	};

	loadData = async () => {
		const { readOnly } = this.props;

		const unidade = await localStorageService.get(UNIDADE_LOGADA_KEY);
		const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);

		this.setState({ ...defaultState, unidade, accessToken, readOnly }, async () => {
			try {
				const response = await findByIdSujeitoAtencaoWithEstadoList(this.props.sujeitoAtencaoId);

				if (response.data.errors) {
					throw response.data.errors[0];
				}

				const { findByIdSujeitoAtencao: patient, findAllEstadoList: states } = response.data.data;

				let state = null;

				if (patient.endereco?.municipio?.estado?.id) {
					state = {
						value: patient.endereco?.municipio?.estado?.id,
						label: patient.endereco?.municipio?.estado?.uf,
					};
				}

				let city = null;

				if (patient.endereco?.municipio?.id) {
					city = {
						value: patient.endereco?.municipio?.id,
						label: patient.endereco?.municipio?.nome,
					};
				}

				this.setState(
					{
						doctor: this.props.profissionalSaude,
						patient: {
							id: patient.id,
							name: patient.nome,
							picture: patient.fotoPerfil,
							street: patient.endereco?.nomeLogradouro || "",
							number: patient.endereco?.numero || "",
							zipCode: patient.endereco?.cep || "",
							neighborhood: patient.endereco?.bairro || "",
							city,
							state,
							isEditing: false,
							isHidden: false,
						},
						states,
					},
					() => {
						if (this.state.patient.state) {
							this.handleUpdateCities(
								this.state.patient.state.value
							);
						}
					}
				);
			} catch (error) {
				console.error(error);
			} finally {
				this.setState({ loadingModal: false });
			}
		});
	};

	handleChangeCEP = async (cep) => {
		cep = cep.replace('-', '').trim();
		if (!cep || cep.length < 8) {
			return;
		}

		const consultaDeCepResponse = await consultaDeCep(cep)
		const endereco = consultaDeCepResponse.data?.data?.consultaDeCep;

		if (!endereco) return;
		
		const { logradouro, bairro, municipio } = endereco;

		this.setState({
			patient: {
				...this.state.patient,
				street: logradouro || this.state.patient.street,
				neighborhood: bairro || this.state.patient.neighborhood,
				state: {
					value: municipio?.estado?.id || this.state.patient.state?.value,
					label: municipio?.estado?.uf || this.state.patient.state?.uf,
				},
				city: {
					value: municipio?.id || this.state.patient.city?.value,
					label: municipio?.nome || this.state.patient.city?.label,
				}
			}
		})
	};

	handleUpdateCities = async (id) => {
		if (!id) {
			return;
		}

		try {
			this.setState({ isFetchingCities: true });
			const response = await findEstadoById(id);
			if (response?.data?.error) {
				throw Error(response.data.error[0]);
			}

			const cities = response.data.data.findByEstado;
			this.setState({ cities });
		} catch (error) {
			console.error(error);
		} finally {
			this.setState({ isFetchingCities: false });
		}
	};

	handleChangeTypeRecipe = (typeCurrentRecipe) => () => {
		const { receituarioStore } = this.props;
		this.setState({
			typeCurrentRecipe
		}, () => {
			receituarioStore.modelosPagAtual = 0;
			receituarioStore.modelos = [];

			if (typeCurrentRecipe === 'OFTALMOLOGICA') {
				receituarioStore.tipoListagemSelecionada = 'modelos';
			} else {
				receituarioStore.tipoListagemSelecionada = 'sugestoes';
			}
			this.loadMoreModelos();
		});
	};

	handleEditPatient = () => {
		this.setState({ patient: { ...this.state.patient, isEditing: true } });
	};

	handleSavePatient = async (e) => {
		e.preventDefault();
		this.setState({ patient: { ...this.state.patient, isEditing: false } });

		const { patient } = this.state;

		try {
			const variables = {
				sujeitoAtencaoId: patient.id,
				endereco: {
					cep: string.removeSpecialChars(
						patient.zipCode
					),
					numero: patient.number,
					nomeLogradouro: patient.street,
					estado: patient.state?.value ? {
						id: patient.state.value,
					} : null,
					municipio: patient.city?.value ?  {
						id: patient.city.value,
					} : null,
					bairro: patient.neighborhood,
				},
			};

			const response = await updateEnderecoSujeitoAtencao(variables);

			if (response?.data?.errors) {
				throw response.data.errors[0];
			}

			this.showAlertMessage({
				message: 'Endereço do sujeito de atenção atualizado com sucesso',
				variant: 'success',
				isOpen: true
			});
		} catch (error) {
			this.showAlertMessage({
				message: 'Falha ao atualizar o endereço do sujeito de atenção.',
				variant: 'error',
				isOpen: true
			});
		}
	};

	handleChangePatient = (field) => (e) => {
		let value = null;
		if (field === "state" || field === "city") {
			value = e;
		} else {
			value = e.target.value;
		}

		this.setState(
			{ patient: { ...this.state.patient, [field]: value } },
			() => {
				if (field === 'zipCode' && value) {
					this.handleChangeCEP(value);
				}
				if (field === "state" && e && e.value) {
					this.handleUpdateCities(e.value);
				}
			}
		);
	};

	handleToggleHiddenPatient = () => {
		this.setState({
			patient: {
				...this.state.patient,
				isHidden: !this.state.patient.isHidden,
			},
		});
	};

	handleEditRecipe = (id) => () => {
		this.setState({
			isEditingRecipe: true,
			[`recipes`]: this.state[`recipes`].map((recipe) =>
				recipe.id === id ? { ...recipe, isEditing: true } : recipe
			),
		});
	};

	handleSaveRecipe = (id) => () => {
		this.setState((prevState) => ({
			isEditingRecipe: false,
			[`recipes`]: prevState[`recipes`].map((recipe) =>
				recipe.id === id ? { ...recipe, isEditing: false } : recipe
			),
		}));
	};

	handleChangeRecipe = () => (name, value, idRecipe) => {
		this.setState((prevState) => ({
			[`recipes`]: prevState[`recipes`].map((recipe) =>
				recipe.id === idRecipe ? { ...recipe, [name]: value } : recipe
			),
		}));
	};

	handleRemoveRecipe = (id) => () => {
		this.setState((prevState) => ({
			[`recipes`]: prevState[`recipes`].filter(
				(recipe) => recipe.id !== id
			),
		}));

	};

	handleNewRecipe = () => {
		const { recipe } = this.state;
		this.setState((prevState) => ({
			[`recipes`]: [
				...prevState[`recipes`],
				{
					...recipe,
					id: prevState.lastIdRecipe,
					useType: recipe.useType || "INTERNO"
				},
			],
			lastIdRecipe: prevState.lastIdRecipe + 1,
			recipe: {
				drug: "",
				amount: "",
				ascription: "",
			},
		}));
	};

	handleNewModelRecipe = (item) => {
		try {
			const { lastIdRecipe } = this.state;
			const converted = item.receitaModeloItens.map((item, index) => {
				const { medicamento, remedio, quantidade, adscricao, receitaItemTipoUso, viaAdministracao } = item;

				return {
					drug: {
						fabricante: medicamento?.fabricante,
						formaFarmaceutica: medicamento?.formaFarmaceutica,
						id: medicamento?.id,
						label: remedio,
						principioAtivo: medicamento?.principioAtivo,
						produto: medicamento?.produto,
						receituario: medicamento?.receituario,
						status: medicamento?.status,
						subtitulo: medicamento?.subtitulo,
						tarja: medicamento?.tarja,
						tipo: medicamento?.tipo,
						titularidade: medicamento?.titularidade,
						value: medicamento?.id,
					},
					amount: quantidade === "0" ? "" : quantidade,
					ascription: { label: adscricao || "" },
					routeAdm: viaAdministracao,
					useType: receitaItemTipoUso || "INTERNO",
					id: lastIdRecipe + index + 1
				}
			});

			this.setState((prevState) => ({
				lastIdRecipe: prevState.lastIdRecipe + item.receitaModeloItens.length,
				recipes: [
					...prevState.recipes,
					...converted
				]
			}));
		} catch (error) {
			const notification = {
				isOpen: true,
				message: 'Erro no modelo selecionado.'
			}
			this.showAlertMessage(notification);
		}
	}

	handleNewModeloReceitaOftalmologica = (oftalmologica) => {
		const newReceitaOftalmologica = Object.keys(oftalmologica).reduce((acc, key) => {
			acc[key] = oftalmologica[key] ?? '';
			return acc;
		}, {});

		this.setState({
			receitaOftalmologica: newReceitaOftalmologica
		});
	}

	showAlertMessage = (notification) => {
		this.setState({ notification });

		const timeoutId = setTimeout(() => {
			this.closeAlertMessage();
			clearTimeout(timeoutId);
		}, 3000);
	};

	closeAlertMessage = () => {
		const notification = {
			isOpen: false,
			message: ''
		};
		this.setState({ notification });
	};

	handleChangeNewRecipe = (name, value, idRecipe) => {
		this.setState((prevState) => ({
			recipe: { ...prevState.recipe, [name]: value },
		}));
	};

	handleChangeOftalmoForm = (name, value) => {
		this.setState((prevState) => ({
			receitaOftalmologica: { ...prevState.receitaOftalmologica, [name]: value },
		}));
	};

	handleCopyRecipe = () => {
		const { receituarioStore } = this.props;
		const typeRecipe = this.state.typeCurrentRecipe;
		this.setState({ isDuplicate: true, readOnly: false, loadingModal: true }, () => {
			receituarioStore.tipoListagemSelecionada = typeRecipe === 'OFTALMOLOGICA' ? 'modelos' : 'sugestoes';
			this.setState({ loadingModal: false });
		});
	};

	handleRecipSignedToken = () => {
		if (!this.state.signToken || this.state.printing)
			return;

		this.setState({ isSigned: true, tokenError: false }, () => { this.handleFinishRecipe() });
	}

	handlePrintRecipSigned = async () => {
		const { receituarioStore } = this.props;
		this.setState({ tokenDialogActionClicked: true });

		receituarioStore.possuiToken = await receituarioStore.possuiTokenAssinaturaValido();

		this.setState({
			openSignQuestionDialog: false,
			openTokenDialog: !receituarioStore.possuiToken
		});

		this.setState({ onOkPrintSigned: true })

		receituarioStore.possuiToken && this.setState({
			isSigned: true,
			tokenError: false
		},
			() => { this.handleFinishRecipe() }
		);
	}

	handlePrintRecipNotSigned = () => {
		this.setState({
			tokenDialogActionClicked: true,
			isSigned: false,
			openSignQuestionDialog: false,
			onCancelPrintSigned: true,
		}, () => { this.handleFinishRecipe() });
	}

	handleFinishRecipe = async () => {
		const { receituarioStore } = this.props;
		const getRecipeData = (r) => {
			const data = {
				adscricao: r?.ascription?.label || "",
				remedio: r.drug.label,
				formaFarmaceutica: r.drug.__isNew__ ? "" : r.drug.subtitulo,
				quantidade: r.amount || "",
				principioAtivo: r.drug.__isNew__ ? "" : r.drug.principioAtivo,
				receitaItemTipoUso: r.useType,
				...(r.routeAdm && { viaAdministracao: r.routeAdm }),
			};

			if (r.drug && r.drug.value && !r.drug.__isNew__) {
				data.medicamento = { id: r.drug.value };
			}

			return data;
		};

		const receitaItens = this.state.recipes.map((r) => ({
			...getRecipeData(r),
		}));

		const token = this.state.signToken.replace(' ', '')
		let receita = {
			sujeitoAtencao: {
				id: this.props.sujeitoAtencaoId,
			},
			tipo: this.state.typeCurrentRecipe,
		};

		if (this.state.typeCurrentRecipe === 'OFTALMOLOGICA') {
			receita.oftalmologica = this.state.receitaOftalmologica
			const editorObservacoes = this.refEditorObservacao.current.handleSubmitFromChild();
			receita.oftalmologica.observacao = editorObservacoes
		} else {
			receita.receitaItens = receitaItens
		}

		this.setState(
			{
				readOnly: true,
				printing: true,
				creatingReceita: true,
				printData: '',
			},
			async () => {
				try {
					if (this.state.receitaCreated && !this.state.isDuplicate) {
						if (this.state.entrada.assinado) {
							if (this.state.onCancelPrintSigned) {
								const response = await receituarioStore.imprimirReceita({ id: this.state.idReceita });
								this.setPrintState(response);
							} else {
								const response = await receituarioStore.imprimirAssinadaReceita(
									receituarioStore.possuiToken
										? { id: this.state.idReceita }
										: {
											id: this.state.idReceita,
											tokenAplicativo: token,
										}
								);
								await this.setPrintStateAssinada(response);
							}
						} else {
							if (this.state.onOkPrintSigned) {
								const response = await receituarioStore.imprimirAssinadaReceita(
									receituarioStore.possuiToken
										? { id: this.state.idReceita }
										: {
											id: this.state.idReceita,
											tokenAplicativo: token,
										}
								);
								await this.setPrintStateAssinada(response);
							} else {
								const response = await receituarioStore.imprimirReceita({ id: this.state.idReceita });
								this.setPrintState(response);
							}
						}
						this.setState({ tokenDialogActionClicked: false });
					} else {
						if (this.state.isSigned) {
							const { receituarioStore } = this.props;
							const response = await receituarioStore.criarEImprimirAssinadaReceita(
								receituarioStore.possuiToken
									? { receita: receita }
									: {
										receita: receita,
										tokenAplicativo: token,
									}
							);
							await this.setPrintStateAssinada(response);
							this.setState({ entrada: response, printing: false });
						} else {
							const response = await receituarioStore.criarEImprimirReceita({ receita: receita });
							this.setPrintState(response);
						}
						this.setState({ tokenDialogActionClicked: false });
					}
					if(this.state.compartilhar){
						this.setState({openModalConfirmacao:true})
					}
				} catch (error) {
					this.printErrorHandle(error);
				}
			}
		);
	};

	printErrorHandle = (error) => {
		console.error(error);
		this.setState({
			creatingReceita: false,
			printing: false,
			signToken: '',
			tokenError: true
		});
	}

	setPrintState = (response) => {
		const { entrada } = this.state;
		const id = response?.id || this.state.idReceita;
		const idEntrada = response?.entradaProntuario?.id || entrada.id;

		this.setState({
			receitaCreated: true,
			creatingReceita: false,
			isDuplicate: false,
			idReceita: id,
			printData: response?.pdf || response,
			signToken: '',
			openTokenDialog: false,
			idEntradaProntuario: idEntrada
		});
	}

	setPrintStateAssinada = async (response) => {
		const token = await localStorageService.get(ACCESS_TOKEN_KEY);
		const id = response?.id || this.state.idReceita;
		this.setState({
			receitaCreated: true,
			creatingReceita: false,
			isDuplicate: false,
			idReceita: id,
			printData: buildUrlDownload(response?.entradaProntuario?.urlDownload || response, token),
			signToken: '',
			openTokenDialog: false
		});
	}

	getErrorMessageNovoModelo = form => {
		if (!form.nome || form.ativo === undefined || form.recipes.length === 0) {
			return 'Favor preencher os campos: nome e remédio.'
		}
	}

	handlePrint = async () => {
		try {
			this.setState({ printing: true });

			const { recipes } = this.state;
			const { receituarioStore, configuracaoImpressaoStore, profissionalSaudeStore, prontuarioStore } = this.props;
			const {utilizaAssinaturaDigital} = profissionalSaudeStore.profissionalSaude
			const {assinaturaDigitalObrigatoria} = prontuarioStore;

			const hasMedicamento = !!(recipes.length);
			if (!hasMedicamento && this.state.typeCurrentRecipe !== 'OFTALMOLOGICA') {
				return;
			}
	
			receituarioStore.amountTemp = "";
			receituarioStore.ascriptionTemp = "";
			receituarioStore.drugTemp = null;
			receituarioStore.recipesTemp = [];
			receituarioStore.usoTemp = null;
	
			receituarioStore.tipoListagemSelecionada = "sugestoes";

			const tipo = getTipoImpressao(this.state.typeCurrentRecipe);

			await configuracaoImpressaoStore.getConfig(tipo);

			const openSignQuestionDialog = assinaturaDigitalObrigatoria || utilizaAssinaturaDigital;
			if (openSignQuestionDialog) {
				this.setState({ openSignQuestionDialog: true });
			} else {
				this.setState({ isSigned: false }, () => { this.handleFinishRecipe() })
			}

		} finally {
			this.setState({ printing: false });
		}
	};

	handleCompartilhar = async () => {
		const { idReceita } = this.state
		try {
			this.setState({hideButton:true})
			this.setState({ compartilhar: true, })
			if (!idReceita) {
				await this.handlePrint()
			} else {
				this.setState({
					hideButton: true,
					openModalConfirmacao: true
				})
			}


		} catch (e) {
			console.error(e)
		} finally {
			this.setState({ isLoading: false })
		}

	}

	handleClickCompartilharWhatsApp = async (e) => {
		this.setState({ isLoading: true })
		const { idReceita, idEntradaProntuario } = this.state
		const { prontuarioStore } = this.props
		const { agendamentoSelected } = prontuarioStore
		const telefone = `${agendamentoSelected.telefoneDDI + agendamentoSelected.telefone}`
		try {
			 await prontuarioStore.enviarItemProntuarioPaciente({ numeroTelefone:telefone, entradaProntuarioId: idEntradaProntuario });
			this.handleCloseModal()
		} catch (e) {
			console.error(e)
		}finally{
			this.setState({ openModalConfirmacao: false })
		}

	}

	alterarIdDrug = () => {
		this.setState({ idDrugSelecionado: "" })
	}

	renderFormNewRecipe = () => (
		<DrugForm
			key="new"
			recipe={this.state.recipe}
			onChange={this.handleChangeNewRecipe}
			onBlur={this.handleNewRecipe}
			idDrugSelecionado={this.state.idDrugSelecionado}
			alterarIdDrug={this.alterarIdDrug}
		/>
	);

	handleCancelSignedPrint = () => {
		this.setState({
			openTokenDialog: false,
			tokenError: false,
			signToken: '',
			tokenDialogActionClicked: false
		})
	}

	handleCloseTokenDialog = () => {
		this.setState({ openSignQuestionDialog: false, tokenDialogActionClicked: false });
	}

	handleCloseModalConfirmacao = ()=>{
		this.setState({openModalConfirmacao:false, compartilhar:false})

	}

	renderContent = () => {
		const { classes, entrada, receituarioStore ,utilizaPlugzapi} = this.props;
		const {
			creatingReceita,
			printing,
			recipes,
			signToken,
			readOnly,
			showDadosImportantesModal,
			isDuplicate,
			tokenDialogActionClicked,
			openModalConfirmacao,
			hideButton,
		} = this.state;

		const disableButtonImpressao = creatingReceita || printing || (this.state.typeCurrentRecipe !== 'OFTALMOLOGICA' && this.state.recipes.length <= 0);
		const remediosUsoInterno = recipes.filter((item) => item.useType === "INTERNO");
		const remediosUsoExterno = recipes.filter((item) => item.useType === "EXTERNO");

		this.onChangeTipoListagem = async (tipoListagem) => {
			const { receituarioStore } = this.props;
			receituarioStore.tipoListagemSelecionada = tipoListagem;

			if (tipoListagem === 'sugestoes') {
				receituarioStore.sugestoesPagAtual = 0;
				receituarioStore.sugestoes = [];

				this.setState({ sugestoesLoading: true });
				await receituarioStore.findAllMedicamentoQuantidadeUso({
					pageableDTO: {
						pageSize: 10,
						pageNumber: receituarioStore.sugestoesPagAtual,
						sortDir: "DESC",
						sortField: "quantidadeUso"
					}
				})
				this.setState({ sugestoesLoading: false });
			}
			else {
				receituarioStore.modelos = [];
				receituarioStore.modelosPagAtual = 0;

				this.setState({ modelosLoadingModal: true });
				await receituarioStore.findAllReceitaModelo({
					searchDTO: {
						ativo: true,
						search: "",
						tipo: this.state.typeCurrentRecipe
					},
					pageableDTO: {
						pageSize: 10,
						pageNumber: receituarioStore.modelosPagAtual,
						sortDir: "DESC",
						sortField: "quantidadeUso"
					}
				});
				this.setState({ modelosLoadingModal: false });

			}
		}

		this.handleClickCriarNovoModelo = () => {
			const { receituarioStore, prontuarioStore } = this.props;
			const { recipe, recipes } = this.state;
			receituarioStore.isProntuario = true;
			receituarioStore.criarNovoModelo = true;
			receituarioStore.amountTemp = recipe.amount;
			receituarioStore.ascriptionTemp = recipe.ascription;
			receituarioStore.drugTemp = recipe.drug;
			receituarioStore.recipesTemp = recipes;
			receituarioStore.usoTemp = recipe.useType;

			receituarioStore.nomeModelo = "";
			receituarioStore.statusNovoModelo = true;

			receituarioStore.typeModal = TypeModalEnumReceituario.NOVO_MODELO_MODAL;
			prontuarioStore.receituario = {
				open: false
			};
			receituarioStore.openModalModelo = true;
			this.onChangeTipoListagem("sugestoes");
		};

		this.handleClickSelecionarModelo = async (modeloSelecionado) => {
			receituarioStore.somarQuantidadeUso(modeloSelecionado.id)
			if (this.state.typeCurrentRecipe === 'OFTALMOLOGICA') {
				const { oftalmologica } = modeloSelecionado;
				this.handleNewModeloReceitaOftalmologica(oftalmologica);
			} else {
				this.handleNewModelRecipe(modeloSelecionado);
			}
		}

		this.selectSugestao = async (item) => {
			const { receituarioStore } = this.props;
			receituarioStore.inputAdscricao = "";
			receituarioStore.inputRemedio = "";

			this.setState({
				idDrugSelecionado: item.medicamento.id,
				recipe: {
					drug: {
						label: item.medicamento.subtitulo ? `${item.medicamento.produto}, ${item.medicamento.subtitulo}` : item.medicamento.produto,
						id: item.medicamento.id
					},
					amount: "",
					ascription: { label: "", value: "" },
				},
			})
		}

		this.handleChangeStatusNovoModelo = (e) => {
			const { receituarioStore } = this.props;
			const value = e.target.value;
			this.setState({ statusNovoModelo: value })
			receituarioStore.statusNovoModelo = value;
		}

		this.handleChangeNomeModelo = (e) => {
			const { receituarioStore } = this.props;
			const value = e.target.value;
			this.setState({ nomeModelo: value })
			receituarioStore.nomeModelo = value;
		}

		this.loadMoreSugestoes = async () => {
			const { receituarioStore } = this.props;
			this.setState({ sugestoesLoading: true })

			if (receituarioStore.sugestoesLoading) {
				return;
			}

			receituarioStore.sugestoesPagAtual = receituarioStore.sugestoes.length > 0 ? receituarioStore.sugestoesPagAtual + 1 : 0;

			await receituarioStore.findAllMedicamentoQuantidadeUso({
				pageableDTO: {
					pageSize: 10,
					pageNumber: receituarioStore.sugestoesPagAtual,
					sortDir: "DESC",
					sortField: "quantidadeUso"
				}
			})
			this.setState({ sugestoesLoading: false })

		}

		this.loadMoreModelos = async (search, clear = false) => {
			const { receituarioStore } = this.props;
			this.setState({ modelosLoadingModal: true })

			if (receituarioStore.modelosLoadingModal) {
				return;
			}

			let page = receituarioStore.modelos.length > 0 ? receituarioStore.modelosPagAtual + 1 : 0;

			if(clear) {
				page = 0;
				receituarioStore.modelos = [];
			}

			receituarioStore.modelosPagAtual = page;
			await receituarioStore.findAllReceitaModelo({
				searchDTO: {
					ativo: true,
					search: search || "",
					tipo: this.state.typeCurrentRecipe
				},
				pageableDTO: {
					pageSize: 10,
					pageNumber: page,
					sortDir: "DESC",
					sortField: "quantidadeUso"
				}
			})
			this.setState({ modelosLoadingModal: false })

		}

		return (
			<React.Fragment>
				{showDadosImportantesModal &&
					<Grid>
						<InformacoesImportantesModal />
					</Grid>
				}

				<Grid className={classes.receita}>
					<DialogTitle classes={{ root: classes.dialogHeader }} className={readOnly ? classes.HeaderborderRadius : null} disableTypography>
						<Grid className={classes.dialogHeaderContent}>
							<div className={classes.dialogTitle}>
								<h1>Receituário</h1>
								<RecipeType className={classes.recipeTypeStyle}
									readOnly={readOnly}
									typeCurrentRecipe={this.state.typeCurrentRecipe}
									handleChangeTypeRecipe={this.handleChangeTypeRecipe}
								/>
							</div>
							<div className={classes.headerButtons}>
								{entrada?.id && !isDuplicate && this.state.entrada?.assinado &&
									<Fab className={classes.buttonCopy}>
										<a
											href={buildUrlDownload(this.state.entrada?.entradaProntuario?.urlDownload, receituarioStore.accessToken)}
											download={`receita_${this.state.entrada?.id}`}
											className={classes.downloadIconLink}
											target="_blank"
											rel="noreferrer"
										>
											<DownloadIcon color="primary" />
										</a>
									</Fab>
								}

								{entrada?.id && readOnly && (
									<Fab
										className={classes.buttonCopy}
										onClick={this.handleCopyRecipe}
										disabled={disableButtonImpressao}
									>
										<DuplicateIcon color="primary" />
									</Fab>
								)}
								
								{utilizaPlugzapi && <Fab
									className={classes.buttonSave}
									onClick={this.handleCompartilhar}
									disabled={disableButtonImpressao}
								>
									<CompartilharIcon />
								</Fab>}
								
								{!hideButton && <Fab
									className={classes.buttonSave}
									onClick={this.handlePrint}
									disabled={disableButtonImpressao}
								>
									{entrada?.id && !this.state.isDuplicate ? <PrintIcon /> :
										<SaveIcon />
									}
								</Fab>}
								{!(entrada?.id || this.state.readOnly) && (
									<Fab
										className={classes.buttonActionMinimize}
										onClick={this.handleMinimize}
									>
										<HorizontalLineIcon color='white' />
									</Fab>
								)}
								<Fab
									className={classes.buttonActionClose}
									onClick={this.handleCloseModal}
								>
									<CloseIcon />
								</Fab>
							</div>
						</Grid>
						<Grid className={classes.headerContent}>
							{this.state.typeCurrentRecipe === "CONTROLE_ESPECIAL" && (
								<Patient
									readOnly={readOnly}
									patient={this.state.patient}
									states={this.state.states}
									cities={this.state.cities}
									handleEdit={this.handleEditPatient}
									handleSave={this.handleSavePatient}
									handleChange={this.handleChangePatient}
									handleToggleHidden={this.handleToggleHiddenPatient}
									handleUpdateCities={this.handleUpdateCities}
									isFetchingCities={this.state.isFetchingCities}
									accessToken={this.state.accessToken}
								/>
							)}
							{!readOnly && this.state.typeCurrentRecipe !== "OFTALMOLOGICA" && this.renderFormNewRecipe()}

						</Grid>
					</DialogTitle>

					<DialogContent
						classes={{ root: readOnly ? classes.semLateralSugestoes : classes.dialogContentBorder }}>
						<div className={classes.dialogContent} >
							{this.state.typeCurrentRecipe === "OFTALMOLOGICA" &&
								<OftalmoForm
									key="new"
									recipe={this.state.recipe}
									onChange={this.handleChangeOftalmoForm}
									receitaOftalmologica={this.state.receitaOftalmologica}
									refEditorObservacao={this.refEditorObservacao}
									readOnly={readOnly}
								/>
							}

							{this.state.typeCurrentRecipe !== "OFTALMOLOGICA" && remediosUsoInterno.length > 0 &&
								<RecipeList
									title="Uso Interno"
									type="internal"
									recipes={remediosUsoInterno}
									drugs={this.state.drugs}
									handleEdit={(id) =>
										this.handleEditRecipe(id)
									}
									handleSave={(id) =>
										this.handleSaveRecipe(id)
									}
									handleRemove={(id) =>
										this.handleRemoveRecipe(id)
									}
									readOnly={readOnly}
									handleChange={() => this.handleChangeRecipe()}
								/>
							}
							{this.state.typeCurrentRecipe !== "OFTALMOLOGICA" && <span className={classes.spanSeparator}></span>}

							{this.state.typeCurrentRecipe !== "OFTALMOLOGICA" && remediosUsoExterno.length > 0 &&
								<RecipeList
									title="Uso Externo"
									type="external"
									recipes={remediosUsoExterno}
									drugs={this.state.drugs}
									handleEdit={(id) =>
										this.handleEditRecipe(id)
									}
									handleSave={(id) =>
										this.handleSaveRecipe(id)
									}
									handleRemove={(id) =>
										this.handleRemoveRecipe(id)
									}
									readOnly={readOnly}
									handleChange={() => this.handleChangeRecipe()}
								/>
							}
						</div>
					</DialogContent>
				</Grid>
				{(!readOnly || this.state.isDuplicate) && <Grid className={classes.lateral}>
					<div className={classes.radioButton}>
						<RadioGroup name="tipoListagem" />
						{this.state.typeCurrentRecipe === "OFTALMOLOGICA" ? (
							<Radio
								value={'modelos'}
								className={classes.botoesSugestoesModelosProntos}
								checked={true}
								icon={DescriptionIcon}
								label={'Modelos'}
							/>) : (
							tipoListagem.map(item =>
								<Radio key={item.value}
									value={item.value}
									className={classes.botoesSugestoesModelosProntos}
									checked={receituarioStore.tipoListagemSelecionada === item.value}
									icon={item.icon}
									onChange={() => this.onChangeTipoListagem(item.value)}
									label={item.name}
								/>
							))
						}
					</div>
					<Lista
						loadMoreSugestoes={this.loadMoreSugestoes}
						selectSugestao={(item) => this.selectSugestao(item)}
						handleClickCriarNovoModelo={this.handleClickCriarNovoModelo}
						loadMoreModelos={this.loadMoreModelos}
						handleClickSelecionarModelo={(item) => this.handleClickSelecionarModelo(item)}
					/>
				</Grid>}

				<TokenDialog
					open={this.state.openSignQuestionDialog}
					handleClose={this.handleCloseTokenDialog}
					onCancel={this.handlePrintRecipNotSigned}
					onOk={this.handlePrintRecipSigned}
					isLoading={tokenDialogActionClicked}
				/>
				<ModalCompatilhar 
				open={openModalConfirmacao} 
				onClose = {this.handleCloseModalConfirmacao}
				onClick={this.handleClickCompartilharWhatsApp}
				/>
				
				<InputTokenDialog
					inputValue={signToken}
					onChange={(e) => this.onFieldChange(e.target.value)}
					open={this.state.openTokenDialog}
					onCancel={this.handleCancelSignedPrint}
					onOk={this.handleRecipSignedToken}
					error={this.state.tokenError}
					loading={printing}
					canPrintSigned={this.props.profissionalSaudeStore.canPrintSigned}
					history={this.props.history}
				/>
				<Notification
					close={this.closeAlertMessage}
					reset={this.closeAlertMessage}
					isOpen={this.state.notification.isOpen}
					variant={this.state.notification?.variant || 'error'}
					message={this.state.notification.message}
				/>
			</React.Fragment>
		);
	};

	onFieldChange(value) {
		this.setState({ signToken: value });
	}

	renderPrintPdf = () => {
		if (this.state.printData) {

			if (this.state.isSigned) {
				window.open(this.state.printData, "_blank");
				this.setState({ printing: false });
				return null;
			}

			printPage(base64ToBlob(this.state.printData));
			this.setState({ printing: false });
			if(!this.state.compartilhar){
				this.handleCloseModal();
			}
			return null;
		}
	};

	titleTipoReceita = () => {
		const { screen } = this.props;
		const { typeCurrentRecipe, readOnly } = this.state;

		let tipoReceituario = typeCurrentRecipe.toLowerCase();

		if (tipoReceituario === "controle_especial") {
			tipoReceituario = " controlada"
		}

		const acao = readOnly ? "Visualizar" : "Novo";

		return `${screen} - ${acao} receituário ${tipoReceituario}`
	}

	render() {
		const { classes, open, receituarioStore } = this.props;
		const { loadingModal, printing, showDadosImportantesModal } = this.state;
		this.setState({ openModalModelo: receituarioStore.openModalModelo })
		return (
			<>
				<PageTitle title={this.titleTipoReceita()} />
				<Dialog
					classes={{
						scrollPaper: classes.scrollPaper,
						paper: showDadosImportantesModal ? classes.paperFullWidth : classes.paper,
					}}
					maxWidth="md"
					open={open}
					fullWidth
				>
					{!loadingModal && this.renderContent()}
					{printing && this.renderPrintPdf()}
					{loadingModal && (
						<Grid
							container
							justify="center"
							alignContent="center"
							style={{ flex: 1 }}
						>
							<CircularProgress size={32} />
						</Grid>
					)}
				</Dialog>
			</>
		);
	}
}

Receituario.defaultProps = {
	readOnly: false,
};

export default withStyles(styles)(Receituario);

import React from "react";
import {
  ContainerTab,
  InputCEPFormStyled,
  InputPhoneFormStyled,
  Row,
  TextFieldSearchStyled,
} from "../../styles";
import Field from "../../components/Field";
import { InputForm } from "../../../../../components/Modal/Input";
import { findAllMunicipio } from "../../../../../services/InternacaoService";
import { consultaDeCep } from "../../../../../services/EnderecoService";

const Acompanhante = ({ internacao, handleField }) => {
  const { acompanhante } = internacao;
  const { nome, endereco, contato, rg, documento } = acompanhante || {};

  const { cep, nomeLogradouro, bairro, municipio, numero } = endereco || {};

  const { telefonePrincipal, telefoneSecundario } = contato || {};

  const handleFieldAcompanhante = (value, field) => {
    const dadosAcompanhante = internacao.acompanhante;
    dadosAcompanhante[field] = value;

    handleField("acompanhante", dadosAcompanhante);
  };

  const loadAllMunicipio = async (search, loadedOptions, { page }) => {
    const searchDTO = {
      search,
      pageNumber: page,
      pageSize: 20,
      sortDir: "ASC",
      sortField: "nome",
    };

    const response = await findAllMunicipio({ searchDTO });
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const onCEPChange = async (e) => {
    const { value } = e.target;

    const cepSemMascara = value.replace(/\D/g, "");
    if (cepSemMascara.length === 8) {
      const response = await consultaDeCep(cepSemMascara);
      const responseConsultaDeCep = response?.data?.data?.consultaDeCep || {};
      if (responseConsultaDeCep) {
        const { logradouro, bairro, municipio } = responseConsultaDeCep;

        const dadosAcompanhante = {
          ...internacao.acompanhante,
          endereco: {
            nomeLogradouro: logradouro,
            bairro,
            municipio,
            uf: municipio?.estado?.uf,
          },
        };

        handleField("acompanhante", dadosAcompanhante);
      }
    }

    handleFieldAcompanhante(value, "cep");
  };

  const handleFieldUpdate = (value, type, field) => {
    const dadosAcompanhante = {
      ...internacao.responsavel,
      [type]: {
        ...internacao.responsavel[type],
        [field]: value,
      },
    };

    handleField("acompanhante", dadosAcompanhante);
  };

  return (
    <ContainerTab>
      <Row>
        <Field label="Nome">
          <InputForm
            value={nome}
            onChange={(e) => handleFieldAcompanhante(e.target.value, "nome")}
          />
        </Field>
      </Row>
      <Row>
        <Field label="RG">
          <InputForm
            value={rg}
            onChange={(e) => handleFieldAcompanhante(e.target.value, "rg")}
          />
        </Field>
        <Field label="CPF">
          <InputForm
            value={documento}
            onChange={(e) =>
              handleFieldAcompanhante(e.target.value, "documento")
            }
          />
        </Field>
      </Row>
      <Row>
        <Field label="Telefone principal">
          <InputPhoneFormStyled
            value={telefonePrincipal}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "contato", "telefonePrincipal")
            }
          />
        </Field>
        <Field label="Telefone secundário">
          <InputPhoneFormStyled
            value={telefoneSecundario}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "contato", "telefoneSecundario")
            }
          />
        </Field>
      </Row>
      <Row>
        <Field label="CEP">
          <InputCEPFormStyled
            type="text"
            onChange={(e) => onCEPChange(e)}
            value={cep}
          />
        </Field>
        <Field label="Endereço">
          <InputForm
            value={nomeLogradouro}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "endereco", "nomeLogradouro")
            }
          />
        </Field>
        <Field label="Número">
          <InputForm
            value={numero}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "endereco", "numero")
            }
          />
        </Field>
      </Row>
      <Row>
        <Field label="Bairro">
          <InputForm
            value={bairro}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "endereco", "bairro")
            }
          />
        </Field>
        <Field label="Cidade">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllMunicipio}
            getOptionLabel={(option) => `${option.nome} - ${option.uf}`}
            getOptionValue={(option) => option.id}
            value={municipio}
            onChange={(e) => handleFieldUpdate(e, "endereco", "municipio")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
      </Row>
    </ContainerTab>
  );
};

export default Acompanhante;

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles/index";
import moment from "moment";
import { CircularProgress, Tooltip} from '@material-ui/core';
import string from "../../utils/string";
import { applyPhoneMask } from "../../utils/PhoneMask";
import { getStatusAtendimento } from '../../utils';
import Colors from "../../template/Colors"
import Notification from '../../components/Notification';
import AtendimentoModal from "./AtendimentoModal";
import Scroll from "../../components/InfiniteScroll/Scroll";
import ColumnInformation from '../../components/Agenda/DayCalendar/ColumnInformation';
import { goToWhatsApp } from '../../utils/goToWhatsApp';
import { getDadosMensagem } from './utils/getDadosMensagem';
import { observer } from 'mobx-react-lite';

const styles = () => ({
    resultado: {
        margin: '20px',
        fontFamily: "Poppins !important",
    },
    lista: {
        overflow: 'hidden !important',
        display: 'grid',
        gridTemplateColumns: '150px 2fr 1fr 1fr 1fr 1fr 1fr',
        gridColumnGap: '10px',
        alignItems: 'center',
        height: 40,
        background: '#F8F9FA',
        color: '#5F6368',
        cursor: "pointer",
        fontFamily: "Poppins !important",
        fontSize: 14,
        border: '1px solid #fff',

        "@media (max-width: 1024px)": {
            gridTemplateColumns: ' 150px 4fr 2fr 4fr',
        },
    },

    dataHora: {
        fontFamily: "Poppins !important",
        display: 'flex',
        flexDirection: 'row',
        minHeight: 40,
        fontSize: 14,
        fontWeight: 600,
        color: 'white',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },

    nome: {
        fontFamily: "Poppins !important",
        fontWeight: 700,
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
        marginLeft: '18px',
    },


    agendado: {
        background: Colors.commons.blueLight,
    },

    aguardando: {
        background: Colors.commons.yellowLight,
    },

    atendendo: {
        background: Colors.commons.waikawaGreyLight,
    },

    atendido: {
        background: Colors.commons.purpleLight,
    },

    bloqueado: {
        background: Colors.commons.bloqueado,
    },

    cancelado: {
        background: Colors.commons.cancelado,
    },

    confirmado: {
        background: Colors.commons.greenLight,
    },

    faltou: {
        background: Colors.commons.pinkLight,
    },

    agendadoDataHora: {
        background: Colors.status.agendado,
        color: 'white',
    },

    aguardandoDataHora: {
        background: Colors.status.aguardando,
        color: 'white',
    },

    atendendoDataHora: {
        background: Colors.status.atendendo,
        color: 'white',
    },

    atendidoDataHora: {
        background: Colors.status.atendido,
        color: 'white',
    },

    bloqueadoDataHora: {
        background: Colors.status.bloqueado,
        color: 'white',
    },

    canceladoDataHora: {
        background: Colors.status.cancelado,
        color: 'white',
    },

    confirmadoDataHora: {
        background: Colors.status.confirmado,
        color: 'white',
    },

    faltouDataHora: {
        background: Colors.status.faltou,
        color: 'white',
    },

    modalEdit: {
        display: 'grid',
        gridTemplateColumns: 'auto 100px 100px 1fr',
        gridColumnGap: '10px',
        maxWidth: 600,
    },

    inputDate: {
        width: 150,

    },

    editName: {
        whiteSpace: 'nowrap',
    },

    listaAgendamento: {
        position: 'relative',
    },

    scrollContainer: {
        height: 'calc(100vh - 255px)',
    },

    headerFiltro: {
        background: '#219A97',
        color: 'white',
        height: 64,
        display: 'grid',
        gridTemplateColumns: '150px 2fr 1fr 1fr 1fr 1fr 1fr',
        fontSize: '16px !important',
        fontWeight: 700,
        alignItems: 'center',
        border: '1px solid white',
        gridColumnGap: '10px',

        "@media (max-width: 1024px)": {
            gridTemplateColumns: '150px 4fr 2fr 4fr',
        }
    },

    spanDataHora: {
        height: '64px',
        background: '#26ACA9',
    },

    headerDataHora: {
        fontSize: '16px !important',
        textAlign: 'center',
        margin: '20px 0',
    },

    headerItens: {
        fontSize: '16px !important',
    },

    headerProfissionalSaude:{
        fontSize: '16px !important',

        "@media (max-width: 1024px)": {
            display: 'none',
        }
    },
    headerSala:{
        fontSize: '16px !important',

        "@media (max-width: 1024px)": {
            display: 'none',
        }
    },

    itemProfissionalSaude:{
        "@media (max-width: 1024px)": {
            display: 'none',
        }
    },

    itemSala:{
        "@media (max-width: 1024px)": {
            display: 'none',
        }
    },
    statusButton: {
        width: 15,
        height: 15,
        marginLeft: 8,
        borderRadius: '50%',
        '&:last-child': {
            marginBottom: 0,
        }
    },
    barraStatusInner: {
        backgroundColor: '#fff',
    },
    contentIcon: {
        width: '18px',
        height: '18px',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#5462E0',
    },
    observacao: {
        marginLeft: '-4px',
    },
    loading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        
    },
});


const ListaAgendamentoPaciente = observer((props) => {
    const { lista, classes, searchPacient, atendimentoStore, loadMore } = props;

    const [notification, setNotification] = useState({
        open: false,
        message: 'Erro',
        type: 'error'
    })
    const [showBarraStatus, setShowBarraStatus] = useState(false)
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [nomesProcedimentosPedido,setNomesProcedimentoPedido]= useState('')

    const openModal = (agendamento) => {
        const { id, horaInicio, horaFim, status, sala } = agendamento || {};

        atendimentoStore.changeOpen({
            id: id || null,
            horaInicio: horaInicio,
            horaFim: horaFim,
            status: status,
            isModalSala: !!sala?.id,
            idSala: sala?.id
        });
    };

    const classSituacao = {
        'AGENDADO': { row: classes.agendado, dataHora: classes.agendadoDataHora },
        'AGUARDANDO': { row: classes.aguardando, dataHora: classes.aguardandoDataHora },
        'ATENDENDO': { row: classes.atendendo, dataHora: classes.atendendoDataHora },
        'ATENDIDO': { row: classes.atendido, dataHora: classes.atendidoDataHora },
        'BLOQUEADO': { row: classes.bloqueado, dataHora: classes.bloqueadoDataHora },
        'CANCELADO': { row: classes.cancelado, dataHora: classes.canceladoDataHora },
        'CONFIRMADO': { row: classes.confirmado, dataHora: classes.confirmadoDataHora },
        'FALTOU': { row: classes.faltou, dataHora: classes.faltouDataHora },
    }

    const closeAlertMessage = () => {
        setNotification(prevState => ({ ...prevState, open: false }))
    }

    const handleSelectEvent = (item) => {
        const { onSelectAgendamento } = props;
        const { sala } = item;
        openModal(item);
        onSelectAgendamento(item, !!sala?.id);

    };

    const loadMorePage = () => {
        if(atendimentoStore.loadingFindAllAgendamentoEEncaixe ||
            atendimentoStore.listaFilter.last ) return;
        loadMore(atendimentoStore.listaFilter.pageNumber + 1);
    };

    const listaExibidaFiltros = () => lista.filter((itemFiltrado) => atendimentoStore.status.includes(itemFiltrado.situacao));
    const handleMouseOverBarraStatus = (e, itemId) => {
        e.stopPropagation();
        if (!itemId) return
        setHoveredItemId(itemId);
        setShowBarraStatus(true);
    };

    const handleMouseOutBarraStatus = (e, itemId) => {
        e.stopPropagation();
        if (!itemId) return
        setHoveredItemId(null);
        setTimeout(() => {
            setShowBarraStatus(false);
        }, 500);
    };

    const handleClickAlteraStatus = async (e, id, situacao) => {
        e.stopPropagation();
        if(!id || !situacao) return;
        const index = atendimentoStore.listaFilter.content.findIndex((item) => item.id === id); 
        await atendimentoStore.updateSituacaoAgendamento({ id, situacao });
        atendimentoStore.listaFilter.content[index].situacao = situacao;
        setShowBarraStatus(false);
    };

    const getProfissionalSaudeOfAgendamento = (agendamento) => {
        const { profissionalSaude, solicitante } = agendamento || {};

        if (profissionalSaude) {
            return profissionalSaude?.nome
        }

        if (solicitante) {
            return solicitante?.nome
        }
    };

   const  handleClickWhatsapp = async (e) => {
        e.stopPropagation();
        await atendimentoStore.getConfiguracaoUnidadeLogada();
        let message = "";
        const dadosMensagem = {
            nomePaciente: item.nome,
            nomeFantasiaUnidade:item.nomeFantasiaUnidade,
            nomeProfissional:item.profissionalSaudeNome,
            horaInicio:item.horaInicio || " - ",
            data:item.data,
            chavePublica:item.chavePublica,
            isAgendamentoVizi: !item.origemHealth,
            remoto:item.remoto,
            linkTelemedicina:item.linkTelemedicina,
            horasPermiteAgendamento: atendimentoStore.objView.horasPermiteAgendamento,
            withEndereco: atendimentoStore.objView.utilizaEnderecoMensagemAgendamento,
            endereco:item?.endereco,
        };
        message = getDadosMensagem(dadosMensagem);
        atendimentoStore.increaseWhatsappMessageCounter(item.id);
        return goToWhatsApp(item.telefone,item.telefoneDDI, message)
    };

    const verificaProcedimentoPedido = (item) => {
        if(!item ) return;
        const {procedimentos} = item ;

        let nomeProcedimento = 'Procedimentos solicitados: ';

        const nomesProcedimentos= procedimentos[0]?.forEach(element => {
            nomeProcedimento = `${nomeProcedimento} ${element.procedimentos.nomeProcedimento},`
        });

        setNomesProcedimentoPedido(
            nomesProcedimentos
        )

    };

    const verificaAniversario = (item) => {
        if(!item)return
        const{sujeitoAtencao}=item;
        const {isAniversarioProximo, isAniversariante} = string.verificaAniversarioProximoADataAtual(sujeitoAtencao?.dataNascimento, 5);

        return {isAniversariante,isAniversarioProximo}
    };


    return (
        <div className={classes.listaAgendamento}>
            <div>
                <div className={classes.resultado}>
                    {`Exibindo ${listaExibidaFiltros().length} resultados para "${searchPacient}"`}
                </div>
                <div className={classes.containerTable}>
                    <div className={classes.headerFiltro}>
                        <span className={classes.spanDataHora}><div className={classes.headerDataHora}> Data e hora </div></span>
                        <div className={classes.headerItens}> Nome </div>
                        <div className={classes.headerItens}> Tipo </div>
                        <div className={classes.headerItens}> Telefone </div>
                        <div className={classes.headerProfissionalSaude}> Profissional de Saúde </div>
                        <div className={classes.headerSala}> Sala </div>
                        <div className={classes.headerSala}> Informações </div>
                    </div>
                    <Scroll
                        loadMore={loadMorePage}
                        hasMore={!atendimentoStore.listaFilter.last}
                        pageStart={0}
                        className={classes.scrollContainer}
                    >
                        {listaExibidaFiltros().map((item, index) => ((
                            <div key={index} className={classNames(classes.lista, classSituacao[item.situacao].row)} onClick={() => handleSelectEvent(item)}>
                                <div
                                onClick={(e) => e.stopPropagation()}
                                onMouseOver={(e) => handleMouseOverBarraStatus(e,item.id)}
                                onMouseLeave={(e) => handleMouseOutBarraStatus(e,item.id)}
                                >
                                    {showBarraStatus && item.id === hoveredItemId ? (
                                <div className={classNames(classes.dataHora, classes.barraStatusInner)}>
                                    {atendimentoStore.situacoesAlteracaoStatus.map(statusItem => (
                                        <Tooltip
                                            title={statusItem.name}
                                            key={statusItem.value}
                                            placement="right"
                                        >
                                            <div
                                                className={classes.statusButton}
                                                style={{background: getStatusAtendimento(statusItem.value).color}}
                                                onClick={(e) =>{
                                                    if(statusItem.value === item.situacao) return;
                                                    handleClickAlteraStatus(e, item.id, statusItem.value)
                                                }}
                                            />
                                        </Tooltip>
                                    ))}
                                </div>
                                ) : (
                                <div className={classNames(classes.dataHora, classSituacao[item.situacao].dataHora)}>
                                    {item.horaInicio ? (
                                        <div className={classes.dataHora}>
                                            {`${moment(item.data).format("DD/MM/YYYY")} às ${item.horaInicio}`}
                                        </div>
                                    ) : (
                                        <div className={classes.dataHora}>
                                            {`${moment(item.data).format("DD/MM")} - Encaixe`}
                                        </div>
                                    )}
                                </div>
                                )}
                                </div>
                                <div className={classNames(classes.nome)}>
                                    {item.sujeitoAtencao ? item.sujeitoAtencao.nomeSocialOuNome : item.nome}
                                </div>
                                <div>
                                    {item.tipo?.descricao && string.capitalize(item.tipo?.descricao)}
                                </div>
                                <div>
                                    {applyPhoneMask(item.sujeitoAtencao ? item.sujeitoAtencao.contato?.telefonePrincipal : item.telefone)}
                                </div>
                                <div className={classes.itemProfissionalSaude}>
                                    {getProfissionalSaudeOfAgendamento(item)}
                                </div>
                                <div className={classes.itemSala}>
                                    {item.sala && item.sala.nome}
                                </div>
                                <div className={classes.itemSala}>
                                    <ColumnInformation
                                        event={item}
                                        handleClickWhatsapp={handleClickWhatsapp}
                                        nomesProcedimentosPedido={nomesProcedimentosPedido}
                                        isAniversarioProximo={verificaAniversario(item).isAniversarioProximo}
                                        isAniversariante={verificaAniversario(item).isAniversariante}
                                        verificaProcedimentoPedido={verificaProcedimentoPedido}
                                        openNotification={atendimentoStore.openNotification}
                                        listaAgendamentoPaciente
                                    />
                                </div>
                            </div>
                        )))}
                        {atendimentoStore.loadingFindAllAgendamentoEEncaixe && (
                            <div className={classes.loading}>
                                <CircularProgress width={"24px"}/>
                            </div>
                        )}
                    </Scroll>
                </div>
            </div>
            { atendimentoStore.open && <AtendimentoModal /> }
            <Notification
                close={closeAlertMessage}
                reset={closeAlertMessage}
                isOpen={notification.open}
                variant={notification.type}
                message={notification.message}
            />
        </div>
    )
})

export default withStyles(styles)(ListaAgendamentoPaciente)